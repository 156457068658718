<template> 
    <v-layout justify-center class="ma-0" fill-height  > 
        <mbs-page-spinner v-if="!Joined_SaleOrder" />  
        <v-card outlined v-else id="print_template" class="ma-1" width="300">
            <v-card v-if="Joined_SaleOrder.company" class="py-5  ma-5" flat >
                <div >
                    <div class="text-center font-weight-bold not-f5">{{MBS.actions.TEXT_UP(Joined_SaleOrder.company_name)}}</div>  
                    <div class="text-center font-weight-bold not-f3">Phone: {{Joined_SaleOrder.company.company_phone_number}}</div>  
                    <div class="text-center not-f3">Email: {{Joined_SaleOrder.company.company_email_address}}</div>  
                    <div class="text-center not-f3">Location Address: {{Joined_SaleOrder.company.company_location_address}}</div>  
                     
                </div>
                <div v-if="Joined_SaleOrder.company.company_tpin" class="text-center not-f3 mt-4">TPIN: {{Joined_SaleOrder.company.company_tpin}}</div> 
            </v-card>
            <v-card outlined class="mx-5 pa-3" tile>
                <div class="text-center font-weight-bold not-f5">INVOICE</div> 
            </v-card>
            <!-- <v-list>
                <v-list-item class="ma-0 pa-5 font-weight-bold not-f5 ">
                    <v-list-item-content>
                        <div class=" not-f">Invoice No: {{Joined_SaleOrder.key}}</div>
                        <div class="not-f">Date: {{Joined_SaleOrder.local_created_at_}}</div>
                    </v-list-item-content>
                    <v-list-item-action>
                        <div class="not-f">Bill To: {{Joined_SaleOrder.customer_name}}</div>
                        <div class="not-f">Account: {{Joined_SaleOrder.receivable_name}}</div>
                    </v-list-item-action>
                </v-list-item>
            </v-list> -->
            <v-layout row  class="ma-0 pa-5 font-weight-bold ">
                <div>
                    <div class="text-center not-f3">Invoice No: {{Joined_SaleOrder.key}}</div>
                    <div class="not-f3">Date: {{Joined_SaleOrder.local_created_at_}}</div>
                </div>
                <v-spacer></v-spacer>
                <div>
                    <div class="not-f3">Bill To: {{Joined_SaleOrder.customer_name}}</div>
                    <div class="not-f3">Account: {{Joined_SaleOrder.receivable_name}}</div>
                </div>
                
            </v-layout>
            <v-divider class="mx-3 " ></v-divider>
            <v-list color="transparent" class="py-0">
                <v-list-item  dense  class="font-weight-bold not-f4 py-0" >
                    <v-list-item-content class="py-0">
                        <div>{{MBS.actions.TEXT_UP('Purchased Items')}}</div> 
                    </v-list-item-content>
                    <v-list-item-action class="py-0"> 
                        <div class="one-line">{{MBS.actions.TEXT_UP('Price')}}</div> 
                    </v-list-item-action>   
                </v-list-item>
                <template>
                    <v-list-item   
                        :key="index"  v-for="(item, index) in Joined_SaleOrder.joined_items">
                        <template >  
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="font-weight-bold">
                                        {{item.item_name}}
                                </v-list-item-title>
                                <v-list-item-title class="" >
                                        {{item.selling_model}}: {{item.quantity}} X {{item.sold_price}}
                                </v-list-item-title> 
                            </v-list-item-content>
                            <v-list-item-action>
                                {{MBS.actions.money(CALCULATE_PRICE(item))}} 
                            </v-list-item-action>   
                        </template> 
                    </v-list-item>
                </template>
                <v-divider class="mx-3 mt-5" ></v-divider>  
                <v-list-item  dense   >
                    <v-list-item-content >
                        <div>Sub-total</div>
                        <div>Tax</div> 
                    </v-list-item-content>
                    <v-list-item-action>
                        <div>MWK {{MBS.actions.money(TotalPrice)}}</div>
                        <div>MWK 0.00</div>
                    </v-list-item-action>   
                </v-list-item>
                
                <v-divider class="mx-3"></v-divider>
                <v-list-item     class="" >
                    <v-list-item-content>
                        <v-layout class="ma-0 one-line " align-center>
                            <div class="font-weight-bold not-f6">TOTAL PRICE</div>
                            <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(Joined_SaleOrder.joined_items)}} items</div> 
                        </v-layout>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-layout class="ma-0 one-line" align-center>
                            <div class="font-weight-bold not-f3 mt-1 mr-2">MWK </div>
                            <div class="font-weight-bold not-f6">{{MBS.actions.money(TotalPrice)}}</div>
                        </v-layout> 
                    </v-list-item-action>   
                </v-list-item>
                <v-divider class="mx-3"></v-divider>
                <!-- <v-list-item  dense   >
                    <v-list-item-content >
                        <div>Cash:</div>
                        <div>Change:</div> 
                    </v-list-item-content>
                    <v-list-item-action>
                        <div>MWK {{MBS.actions.money(Joined_SaleOrder.given_amount)}}</div>
                        <div>MWK {{MBS.actions.money(Joined_SaleOrder.given_amount-Joined_SaleOrder.paid_amount)}}</div>
                    </v-list-item-action>   
                </v-list-item> -->
            </v-list>    
            <v-layout justify-center>
                <mbs-barcode 
                tag="svg"
                :value="Joined_SaleOrder.key"
                :options="{height:50,width:1.8,margin:10}"/>  
            </v-layout>
            <div class="mx-1">
                <v-list>
                    <v-list-item  dense   >
                        <v-list-item-content >
                            <div>Date:</div>
                            <div>Time:</div> 
                        </v-list-item-content>
                        <v-list-item-action>
                            <div>{{MBS.actions.toDate(Joined_SaleOrder.local_created_at)}}</div>
                            <div>{{MBS.actions.toTime(Joined_SaleOrder.local_created_at)}}</div>
                        </v-list-item-action>   
                    </v-list-item>
                </v-list>
                <v-divider class="mx-3"></v-divider> 
                
            </div >
            <div class="mx-3 py-2">
                <v-layout justify-center align-center class="pt-5">
                    <div>Teller:</div>{{Joined_SaleOrder.teller_name}}
                </v-layout>
                <v-layout justify-center align-center class="pb-5">
                    <div>Outlet:</div>{{Joined_SaleOrder.till_name}}:<div>till:</div>{{Joined_SaleOrder.till_name}}
                </v-layout>
                <v-layout class="mb-1" row="" justify-center align-center v-if="Joined_SaleOrder.customer_name">
                    <div>Customer:</div>{{Joined_SaleOrder.customer_name}}
                </v-layout>
            </div>
            <v-divider class="mx-3"></v-divider> 
            <v-layout column justify-center align-center class="one-line py-5" >
                <div class="font-weight-bold not-f2">POWERED BY MBERA SOLUTION</div>
                <div class="font-weight-bold not-f2">{{DATA.APP.ADMIN_PHONE}}</div> 
            </v-layout> 
            <br><br>
        </v-card>
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    let NAME = 'DIALOG'
    export default {
        props:['data','type'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true
            } 
        }, 
        mounted(){ 
        },
        computed:{
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            SaleOrder(){ 
                let data = this.data
                return  data
            },
            Joined_SaleOrder(){ 
                let item = this.SaleOrder 
                let joined = this.MBS.actions.JOIN_SALE_ORDER(item) 
                // console.log(joined,'.....j');
                return joined
            }, 
            TotalPrice(){
                try { 
                    let items = this.Joined_SaleOrder?this.Joined_SaleOrder.joined_items:null
                    let total_price = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let sold_price = this.toNumber(item.sold_price)
                        let discount = this.toNumber(item.discount)
                        let quantity = this.toNumber(item.quantity)
                        total_price = total_price + (quantity*(sold_price-discount))
                    });  
                    return total_price
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
        },
        methods:{
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
<style scoped>
    .full-width{
        width: 100% !important;
    }
    .full-height{
        height: 100% !important;
    }
    .one-line{
        white-space: nowrap;
    }
</style>
